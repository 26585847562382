<template>
  <div>
    <vx-card title="Inbound - POD Return">
      <vs-tabs value="3">
        <vs-tab @click="goPurchaseOrder" index="0" label="Purchase Order"></vs-tab>
        <vs-tab @click="goInterWarehouse" index="1" label="Inter Warehouse"></vs-tab>
        <vs-tab @click="goCustomerReturn" index="2" label="Customer Return"></vs-tab>
        <vs-tab index="3" label="POD Return"></vs-tab>
      </vs-tabs>
      <div class="mt-3 ml-1 vx-row">
        <vs-tabs :value="activeTab">
          <vs-tab label="Receive">
            <tab-receive></tab-receive>
          </vs-tab>          
          <vs-tab label="Put-away">
            <tab-put-away></tab-put-away>
          </vs-tab>
          <vs-tab label="Complete">
            <tab-complete></tab-complete>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import TabReceive from "./component/TabReceive.vue";
import TabPutAway from "./component/TabPutAway.vue";
import TabComplete from "./component/TabComplete.vue";
export default {
  components: {
    TabReceive,
    TabPutAway,
    TabComplete,
  },  
  data() {
    return {
      activeTab: sessionStorage.getItem('activeTabPODR') ? sessionStorage.getItem('activeTabPODR') : "0",
      draw: 0,
      transactionDate: null,
      selectedSrcWarehouse: null
    }
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
    goPurchaseOrder() {
      this.$router.push({
        name: this.$store.state.inbound.purchaseOrder.baseRouterName,
      });
    },
    goCustomerReturn() {
      this.$router.push({
        name: this.$store.state.inbound.customerReturn.baseRouterName,
      });
    },
    goInterWarehouse() {
      this.$router.push({
        name: this.$store.state.inbound.interWarehouse.baseRouterName,
      });
    },
  }
}
</script>
